import temporal from '@pretto/bricks/core/utility/temporal'

import { Rate } from '@pretto/website/src/components/Rates/RatesTable/types/Rate.type'
import { CheckProps } from '@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/components/Check/Check'
import { HeroPictureRate } from '@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/components/HeroPictureRate/HeroPictureRate'
import { filterRatesByDurationProfilIncomeRegionMortgagors } from '@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/lib/filterRatesByDurationProfilIncomeRegionMortgagors'

interface HeroPictureRateContainerProps {
  checks: CheckProps[]
  ctaLabel: string
  ctaUrl: string
  duration: '120' | '180' | '240' | '300'
  image: string
  linkLabel: string
  linkUrl: string
  rates: Rate[]
  rateTitle?: string
  text: string
  title: string
}

export const HeroPictureRateContainer: React.FC<HeroPictureRateContainerProps> = ({
  checks,
  ctaLabel,
  ctaUrl,
  duration = '240',
  image,
  linkLabel,
  linkUrl,
  rates,
  rateTitle,
  text,
  title,
}) => {
  const { trend, rate } = filterRatesByDurationProfilIncomeRegionMortgagors({
    rates,
    duration: parseInt(duration),
  })

  const heroPictureRateContainerProps = {
    checks,
    ctaLabel,
    ctaUrl,
    image,
    text,
    title,
    rateSectionProps: {
      ctaLabel: linkLabel,
      ctaUrl: linkUrl,
      duration: parseInt(duration),
      rate: rate.toLocaleString('fr', { maximumFractionDigits: 2 }),
      title: rateTitle ?? `Meilleur taux sur ${parseInt(duration) / 12} ans en ${temporal().format('MMMM YYYY')}`,
      trend,
    },
  }

  return <HeroPictureRate {...heroPictureRateContainerProps} />
}
