import {
  Check,
  CheckProps,
} from '@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/components/Check/Check'
import { RateSectionProps } from '@pretto/website/src/templates/home/homeBlocs/rows/heroPictureRate/components/RateSection/RateSection'

import * as S from './HeroPictureRate.styles'

export interface HeroPictureRateProps {
  checks: CheckProps[]
  ctaLabel: string
  ctaUrl: string
  image: string
  text: string
  title: string
  rateSectionProps: RateSectionProps
}

export const HeroPictureRate: React.FC<HeroPictureRateProps> = ({
  checks,
  ctaLabel,
  ctaUrl,
  image,
  text,
  title,
  rateSectionProps,
  ...props
}) => (
  <S.HeroPictureRateContainer {...props}>
    <S.LeftSection>
      <S.Title>{title}</S.Title>

      <S.Text>{text}</S.Text>

      <S.CTA href={ctaUrl}>{ctaLabel}</S.CTA>

      {checks.length > 0 && (
        <S.Checks>
          {checks.map(({ label }) => (
            <Check key={label} label={label} />
          ))}
        </S.Checks>
      )}
    </S.LeftSection>

    <S.RightSection>
      <S.Wrapper>
        <S.ImageWrapper>
          <S.Image path={image} />
        </S.ImageWrapper>

        <S.DarkFooter />

        <S.RateSection {...rateSectionProps} />
      </S.Wrapper>
    </S.RightSection>
  </S.HeroPictureRateContainer>
)
